import React from "react";
import PG_FFC_CONTENT from "../index.content";
import TrackedLink from "../../Analytics/TrackedLink";
import SectionFiveImg from "./SectionFiveImg";
import "./index.scss";

const SectionFive = () => {
  const data = PG_FFC_CONTENT.SecFive;
  return (
    <section className="PgFFC-SectionFive" id="secFive">
      <div className="PgFFC-SectionFive__container">
        <SectionFiveImg data={data} />
        <div className="PgFFC-SectionFive__blockBtn">
          <div className="PgFFC__title PgFFC-SectionFive__blockBtn-text">
            Build your Amazon business with a comprehensive solution for
            finding, sourcing, and selling products!
          </div>
          <TrackedLink
            category="FbaFeeCalculatorLP"
            action="GetAMZScoutNow"
            target
            class="PgFFC__btn"
            path="https://learn.amzsc.amzgb.com/amzscout-amazon-sellers-bundle"
          >
            Get AMZSCOUT NOW
          </TrackedLink>
        </div>
      </div>
    </section>
  );
};

export default SectionFive;
